import React from 'react'

export default function ConnectPrinterIcon() {
  return (
    <svg width="248" height="331" viewBox="0 0 248 331" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="247" height="330" rx="1.5" fill="white" />
      <rect x="0.5" y="0.5" width="247" height="330" rx="1.5" stroke="#000000" />
      <path
        d="M160.684 193.359V181.266V181.115V180.062H86.4953H85.4432V181.264V193.357H38.7812V249.393H85.4432V281.934H160.684V249.395H209.219V193.359H160.684ZM87.5474 182.169H158.58V193.359H87.5474V182.169ZM158.58 279.832H87.5474V240.322H158.58V279.832ZM207.115 247.291H160.684V238.217H85.4432V247.293H40.8854V195.463H207.115V247.291Z"
        fill="#000000"
      />
      <path d="M150.597 252.61H95.8401V254.714H150.597V252.61Z" fill="#000000" />
      <path d="M150.437 261.058H95.6823V263.162H150.437V261.058Z" fill="#000000" />
      <path d="M150.281 269.506H95.5245V271.61H150.281V269.506Z" fill="#000000" />
      <path d="M56.3996 203.055H47.0297V205.159H56.3996V203.055Z" fill="#000000" />
      <path d="M72.7067 203.055H63.3369V205.159H72.7067V203.055Z" fill="#000000" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124 142C117.172 142 110.344 144.543 105.136 149.63C105.093 149.67 105.06 149.718 105.036 149.77C105.013 149.823 105.001 149.88 105 149.937C104.999 149.994 105.011 150.051 105.033 150.104C105.055 150.158 105.088 150.206 105.129 150.246C105.171 150.287 105.22 150.319 105.275 150.341C105.329 150.363 105.387 150.373 105.446 150.373C105.505 150.372 105.563 150.36 105.617 150.337C105.671 150.315 105.72 150.282 105.76 150.24C115.839 140.396 132.161 140.396 142.24 150.24C142.28 150.282 142.329 150.315 142.383 150.337C142.437 150.36 142.495 150.372 142.554 150.373C142.613 150.373 142.671 150.363 142.725 150.341C142.78 150.319 142.829 150.287 142.871 150.246C142.912 150.206 142.945 150.158 142.967 150.104C142.989 150.051 143.001 149.994 143 149.937C142.999 149.88 142.987 149.823 142.964 149.77C142.94 149.718 142.907 149.67 142.864 149.63C137.656 144.543 130.828 142 124 142Z"
        fill="#000000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124 146.575C118.37 146.575 112.74 148.672 108.448 152.866C108.407 152.906 108.373 152.953 108.35 153.006C108.327 153.058 108.315 153.115 108.315 153.172C108.314 153.229 108.326 153.286 108.348 153.339C108.37 153.392 108.403 153.44 108.444 153.48C108.486 153.521 108.535 153.552 108.589 153.574C108.643 153.596 108.701 153.607 108.76 153.606C108.818 153.606 108.876 153.594 108.93 153.572C108.984 153.549 109.033 153.517 109.073 153.476C117.319 145.418 130.681 145.418 138.927 153.476C138.967 153.517 139.016 153.549 139.07 153.572C139.124 153.594 139.182 153.606 139.24 153.606C139.299 153.607 139.357 153.596 139.411 153.574C139.465 153.552 139.514 153.521 139.556 153.48C139.597 153.44 139.63 153.392 139.652 153.339C139.674 153.286 139.686 153.229 139.685 153.172C139.685 153.115 139.673 153.058 139.65 153.006C139.627 152.953 139.593 152.906 139.551 152.866C135.26 148.672 129.63 146.575 124 146.575Z"
        fill="#000000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124 151.15C119.569 151.15 115.139 152.8 111.761 156.101C111.719 156.141 111.686 156.189 111.663 156.241C111.64 156.294 111.628 156.351 111.627 156.408C111.627 156.465 111.638 156.522 111.66 156.575C111.682 156.628 111.715 156.676 111.757 156.716C111.798 156.757 111.847 156.789 111.902 156.81C111.956 156.832 112.014 156.843 112.072 156.843C112.131 156.842 112.189 156.83 112.243 156.808C112.297 156.785 112.345 156.752 112.386 156.711C118.803 150.44 129.197 150.44 135.614 156.711C135.655 156.752 135.703 156.785 135.757 156.808C135.811 156.83 135.869 156.842 135.928 156.843C135.986 156.843 136.044 156.832 136.098 156.81C136.153 156.789 136.202 156.757 136.243 156.716C136.285 156.676 136.318 156.628 136.34 156.575C136.362 156.522 136.373 156.465 136.373 156.408C136.372 156.351 136.36 156.294 136.337 156.241C136.314 156.189 136.281 156.141 136.239 156.101C132.861 152.8 128.431 151.15 124 151.15Z"
        fill="#000000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124 155.727C120.769 155.727 117.538 156.929 115.074 159.332C115.032 159.372 114.998 159.42 114.975 159.472C114.951 159.525 114.939 159.582 114.938 159.639C114.938 159.697 114.949 159.754 114.971 159.807C114.993 159.86 115.026 159.908 115.068 159.949C115.109 159.989 115.159 160.022 115.213 160.043C115.267 160.065 115.326 160.076 115.385 160.075C115.443 160.075 115.501 160.063 115.555 160.04C115.609 160.017 115.658 159.984 115.699 159.943C120.287 155.466 127.713 155.466 132.301 159.943C132.342 159.984 132.391 160.017 132.445 160.04C132.499 160.063 132.557 160.075 132.615 160.075C132.674 160.076 132.733 160.065 132.787 160.043C132.841 160.022 132.891 159.989 132.932 159.949C132.974 159.908 133.007 159.86 133.029 159.807C133.051 159.754 133.062 159.697 133.062 159.639C133.061 159.582 133.049 159.525 133.025 159.472C133.002 159.42 132.968 159.372 132.926 159.332C130.462 156.929 127.231 155.727 124 155.727Z"
        fill="#000000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124 160.302C121.968 160.302 119.936 161.058 118.387 162.568C118.345 162.608 118.312 162.655 118.289 162.708C118.266 162.76 118.254 162.817 118.253 162.874C118.253 162.931 118.264 162.988 118.286 163.041C118.308 163.094 118.341 163.142 118.382 163.183C118.424 163.223 118.473 163.255 118.527 163.277C118.581 163.299 118.639 163.31 118.698 163.309C118.756 163.309 118.814 163.297 118.868 163.275C118.922 163.252 118.971 163.219 119.012 163.178C121.771 160.487 126.229 160.487 128.989 163.178C129.029 163.219 129.078 163.252 129.132 163.275C129.186 163.297 129.244 163.309 129.302 163.309C129.361 163.31 129.419 163.299 129.473 163.277C129.527 163.255 129.576 163.223 129.618 163.183C129.659 163.142 129.692 163.094 129.714 163.041C129.736 162.988 129.747 162.931 129.747 162.874C129.747 162.817 129.734 162.76 129.711 162.708C129.688 162.655 129.655 162.608 129.613 162.568C128.064 161.058 126.032 160.302 124 160.302Z"
        fill="#000000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124 164.629C122.489 164.629 121.253 165.837 121.253 167.317C121.253 168.793 122.489 170 124 170C125.515 170 126.752 168.793 126.752 167.317C126.752 165.837 125.515 164.629 124 164.629ZM124 165.492C125.038 165.492 125.869 166.303 125.869 167.317C125.869 168.325 125.038 169.137 124 169.137C122.967 169.137 122.136 168.325 122.136 167.317C122.136 166.303 122.967 165.492 124 165.492Z"
        fill="#000000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124 142C117.172 142 110.344 144.543 105.136 149.63C105.093 149.67 105.06 149.718 105.036 149.77C105.013 149.823 105.001 149.88 105 149.937C104.999 149.994 105.011 150.051 105.033 150.104C105.055 150.158 105.088 150.206 105.129 150.246C105.171 150.287 105.22 150.319 105.275 150.341C105.329 150.363 105.387 150.373 105.446 150.373C105.505 150.372 105.563 150.36 105.617 150.337C105.671 150.315 105.72 150.282 105.76 150.24C115.839 140.396 132.161 140.396 142.24 150.24C142.28 150.282 142.329 150.315 142.383 150.337C142.437 150.36 142.495 150.372 142.554 150.373C142.613 150.373 142.671 150.363 142.725 150.341C142.78 150.319 142.829 150.287 142.871 150.246C142.912 150.206 142.945 150.158 142.967 150.104C142.989 150.051 143.001 149.994 143 149.937C142.999 149.88 142.987 149.823 142.964 149.77C142.94 149.718 142.907 149.67 142.864 149.63C137.656 144.543 130.828 142 124 142Z"
        stroke="#000000"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124 146.575C118.37 146.575 112.74 148.672 108.448 152.866C108.407 152.906 108.373 152.953 108.35 153.006C108.327 153.058 108.315 153.115 108.315 153.172C108.314 153.229 108.326 153.286 108.348 153.339C108.37 153.392 108.403 153.44 108.444 153.48C108.486 153.521 108.535 153.552 108.589 153.574C108.643 153.596 108.701 153.607 108.76 153.606C108.818 153.606 108.876 153.594 108.93 153.572C108.984 153.549 109.033 153.517 109.073 153.476C117.319 145.418 130.681 145.418 138.927 153.476C138.967 153.517 139.016 153.549 139.07 153.572C139.124 153.594 139.182 153.606 139.24 153.606C139.299 153.607 139.357 153.596 139.411 153.574C139.465 153.552 139.514 153.521 139.556 153.48C139.597 153.44 139.63 153.392 139.652 153.339C139.674 153.286 139.686 153.229 139.685 153.172C139.685 153.115 139.673 153.058 139.65 153.006C139.627 152.953 139.593 152.906 139.551 152.866C135.26 148.672 129.63 146.575 124 146.575Z"
        stroke="#000000"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124 151.15C119.569 151.15 115.139 152.8 111.761 156.101C111.719 156.141 111.686 156.189 111.663 156.241C111.64 156.294 111.628 156.351 111.627 156.408C111.627 156.465 111.638 156.522 111.66 156.575C111.682 156.628 111.715 156.676 111.757 156.716C111.798 156.757 111.847 156.789 111.902 156.81C111.956 156.832 112.014 156.843 112.072 156.843C112.131 156.842 112.189 156.83 112.243 156.808C112.297 156.785 112.345 156.752 112.386 156.711C118.803 150.44 129.197 150.44 135.614 156.711C135.655 156.752 135.703 156.785 135.757 156.808C135.811 156.83 135.869 156.842 135.928 156.843C135.986 156.843 136.044 156.832 136.098 156.81C136.153 156.789 136.202 156.757 136.243 156.716C136.285 156.676 136.318 156.628 136.34 156.575C136.362 156.522 136.373 156.465 136.373 156.408C136.372 156.351 136.36 156.294 136.337 156.241C136.314 156.189 136.281 156.141 136.239 156.101C132.861 152.8 128.431 151.15 124 151.15Z"
        stroke="#000000"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124 155.727C120.769 155.727 117.538 156.929 115.074 159.332C115.032 159.372 114.998 159.42 114.975 159.472C114.951 159.525 114.939 159.582 114.938 159.639C114.938 159.697 114.949 159.754 114.971 159.807C114.993 159.86 115.026 159.908 115.068 159.949C115.109 159.989 115.159 160.022 115.213 160.043C115.267 160.065 115.326 160.076 115.385 160.075C115.443 160.075 115.501 160.063 115.555 160.04C115.609 160.017 115.658 159.984 115.699 159.943C120.287 155.466 127.713 155.466 132.301 159.943C132.342 159.984 132.391 160.017 132.445 160.04C132.499 160.063 132.557 160.075 132.615 160.075C132.674 160.076 132.733 160.065 132.787 160.043C132.841 160.022 132.891 159.989 132.932 159.949C132.974 159.908 133.007 159.86 133.029 159.807C133.051 159.754 133.062 159.697 133.062 159.639C133.061 159.582 133.049 159.525 133.025 159.472C133.002 159.42 132.968 159.372 132.926 159.332C130.462 156.929 127.231 155.727 124 155.727Z"
        stroke="#000000"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124 160.302C121.968 160.302 119.936 161.058 118.387 162.568C118.345 162.608 118.312 162.655 118.289 162.708C118.266 162.76 118.254 162.817 118.253 162.874C118.253 162.931 118.264 162.988 118.286 163.041C118.308 163.094 118.341 163.142 118.382 163.183C118.424 163.223 118.473 163.255 118.527 163.277C118.581 163.299 118.639 163.31 118.698 163.309C118.756 163.309 118.814 163.297 118.868 163.275C118.922 163.252 118.971 163.219 119.012 163.178C121.771 160.487 126.229 160.487 128.989 163.178C129.029 163.219 129.078 163.252 129.132 163.275C129.186 163.297 129.244 163.309 129.302 163.309C129.361 163.31 129.419 163.299 129.473 163.277C129.527 163.255 129.576 163.223 129.618 163.183C129.659 163.142 129.692 163.094 129.714 163.041C129.736 162.988 129.747 162.931 129.747 162.874C129.747 162.817 129.734 162.76 129.711 162.708C129.688 162.655 129.655 162.608 129.613 162.568C128.064 161.058 126.032 160.302 124 160.302Z"
        stroke="#000000"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124 164.629C122.489 164.629 121.253 165.837 121.253 167.317C121.253 168.793 122.489 170 124 170C125.515 170 126.752 168.793 126.752 167.317C126.752 165.837 125.515 164.629 124 164.629ZM124 165.492C125.038 165.492 125.869 166.303 125.869 167.317C125.869 168.325 125.038 169.137 124 169.137C122.967 169.137 122.136 168.325 122.136 167.317C122.136 166.303 122.967 165.492 124 165.492Z"
        stroke="#000000"
        strokeWidth="0.5"
      />
      <path
        d="M163.712 59.0659H79.4054C79.1637 59.0659 78.9688 59.2609 78.9688 59.5025V118.495C78.9688 118.737 79.1637 118.932 79.4054 118.932H163.712C163.953 118.932 164.148 118.737 164.148 118.495V59.5037C164.15 59.262 163.953 59.0659 163.712 59.0659ZM163.275 118.059H79.842V59.9403H163.275V118.059Z"
        fill="#000000"
        stroke="#000000"
      />
      <path
        d="M173.889 54.9153C172.756 53.4937 171.041 52.5874 169.126 52.5874H78.8756C75.4704 52.5874 72.7002 55.4545 72.7002 58.9782V119.022C72.7002 122.546 75.4704 125.413 78.8756 125.413H169.125C171.04 125.413 172.755 124.505 173.888 123.085C174.77 121.979 175.3 120.563 175.3 119.022V58.9782C175.3 57.437 174.77 56.0211 173.889 54.9153ZM174.426 119.022C174.426 119.592 174.343 120.143 174.188 120.661C173.515 122.906 171.501 124.54 169.125 124.54H78.8756C75.9526 124.54 73.5746 122.065 73.5746 119.022V58.9782C73.5746 55.9356 75.9526 53.4606 78.8756 53.4606H169.125C171.501 53.4606 173.515 55.0943 174.188 57.3389C174.343 57.8565 174.426 58.4071 174.426 58.9782V119.022Z"
        fill="#000000"
        stroke="#000000"
      />
      <line x1="169.44" y1="83.2034" x2="169.44" y2="94.7966" stroke="#000000" strokeWidth="2" />
    </svg>
  )
}
