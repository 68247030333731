import localForage from 'localforage'
import { AppState } from '../../types/state'

export default class AppStateBackup {
  private static instance: AppStateBackup

  private static store: LocalForage

  private STORAGE_KEY = 'app_state_backup'

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static getInstance(): AppStateBackup {
    if (!AppStateBackup.instance) {
      AppStateBackup.instance = new AppStateBackup()
      AppStateBackup.store = localForage
    }

    return AppStateBackup.instance
  }

  public async getBackup(): Promise<AppState.State | null | undefined> {
    const state: AppState.State | null | undefined = await AppStateBackup.store.getItem(this.STORAGE_KEY)
    return state
  }

  public async storeBackup(state: AppState.State) {
    return AppStateBackup.store.setItem(this.STORAGE_KEY, state)
  }
}
