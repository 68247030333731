import React, { ReactElement } from 'react'
import { Elements } from '../../types/elements'
import { DEFAULT_LIST_CLASS } from './_config'

export default function List({
  className = '',
  type: Element,
  modifier,
  children,
  horizontal = false,
  reset = false,
  fancy = false,
}: Elements.List): ReactElement {
  const classes = `
    ${DEFAULT_LIST_CLASS}
    ${className}
    ${modifier ? `${DEFAULT_LIST_CLASS}--${modifier}` : ''}
    ${horizontal ? `${DEFAULT_LIST_CLASS}--horizontal` : ''}
    ${reset ? `${DEFAULT_LIST_CLASS}--reset` : ''}
    ${fancy ? `${DEFAULT_LIST_CLASS}--fancy` : ''}
  `
    .trim()
    .replace(/\s+/gm, ' ')

  return <Element className={classes}>{children}</Element>
}
