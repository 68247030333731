import React, { forwardRef, RefObject } from 'react'
import { Elements } from '../../types/elements'
import {
  DEFAULT_INPUT_FIELD_CLASS,
  DEFAULT_INPUT_FIELD_ERROR_CLASS,
  DEFAULT_INPUT_FIELD_MAX_LENGTH_CLASS,
  DEFAULT_INPUT_FIELD_TOOLTIP_CLASS,
} from './_config'

const InputField = forwardRef(
  (
    { children, className = '', error = false, hasMaxLength = false, hasTooltip = false, id = '' }: Elements.InputField,
    reference: RefObject<HTMLDivElement>,
  ) => {
    return (
      <div
        ref={reference}
        id={id}
        className={`${DEFAULT_INPUT_FIELD_CLASS} ${hasMaxLength ? DEFAULT_INPUT_FIELD_MAX_LENGTH_CLASS : ''} ${
          error ? DEFAULT_INPUT_FIELD_ERROR_CLASS : ''
        } ${hasTooltip ? DEFAULT_INPUT_FIELD_TOOLTIP_CLASS : ''} ${className}`}
      >
        {children}
      </div>
    )
  },
)

export default InputField
