import React, { ReactElement, useMemo } from 'react'
import { Elements } from '../../types/elements'

export default function MaxLengthIndicator({
  maxLength,
  currentValue,
  className = '',
}: Elements.MaxLength): ReactElement {
  const atLimit = useMemo(() => currentValue.length === maxLength, [currentValue.length, maxLength])

  return (
    <div className={`e__max-length ${className} ${atLimit ? 'e__max-length--error' : ''}`}>
      {currentValue.length}/{maxLength}
    </div>
  )
}
