import React from 'react'
import { Elements } from '../../../types/elements'

export default function InformationIcon({ circleFill = '#000000', textFill = 'white' }: Elements.InformationIcon) {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11.5" cy="11.5" r="11.5" fill={circleFill} />
      <rect x="11" y="9" width="1" height="8" fill={textFill} />
      <rect x="11" y="6" width="1" height="1" fill={textFill} />
    </svg>
  )
}
