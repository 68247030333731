import React from 'react'
import CensoredWordsInstance from '../lib/censored-words'
import googleAnalytics from '../lib/google-analytics'
import isOnline from '../utils/get-online-status'
import IsBrowser from '../utils/is-browser'
import { AppProvider } from './app-context'

// eslint-disable-next-line react/prop-types
export default ({ element }) => {
  if (IsBrowser()) {
    googleAnalytics.configureDimensions()

    if (isOnline) {
      const backupCensoredWords = async () => {
        const censorePromises = [CensoredWordsInstance.backupWords('en'), CensoredWordsInstance.backupWords('zh')]
        await Promise.all(censorePromises)
      }
      backupCensoredWords()
    }
  }

  return <AppProvider>{element}</AppProvider>
}
