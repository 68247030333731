import wrapWithContext from './src/state/wrap-with-context.tsx'

// require('source-map-support').install()
// require('ts-node').register()

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = wrapWithContext

export const onServiceWorkerUpdateReady = () => {
  if (typeof window !== 'undefined') {
    const root = document.querySelector('#___gatsby')

    if (root) {
      const updatePopup = document.createElement('div')
      updatePopup.id = 'c__update-popup'
      updatePopup.classList.add('animated', 'animated--fade-in-up')

      const message = document.createElement('span')
      message.innerHTML = 'An update for this app is available'

      const button = document.createElement('button')
      button.innerHTML = 'Update'
      button.addEventListener('click', (event) => {
        event.preventDefault()
        window.location.reload()
      })

      updatePopup.append(message)
      updatePopup.append(button)

      root.append(updatePopup)
    }
  }
}

export const onServiceWorkerInstalled = () => {
  const mqStandAlone = '(display-mode: standalone)'
  // In browser
  if (typeof window !== 'undefined') {
    setTimeout(() => {
      // It is a PWA environment
      if (window.matchMedia(mqStandAlone).matches) {
        const root = document.querySelector('#___gatsby')

        if (root) {
          const updatePopup = document.createElement('div')
          updatePopup.id = 'c__update-popup'
          updatePopup.classList.add('animated', 'animated--fade-in-up')

          const message = document.createElement('span')
          message.innerHTML = `The app is ready for offline use`

          const button = document.createElement('button')
          button.innerHTML = 'Dismiss'
          button.addEventListener('click', (event) => {
            event.preventDefault()
            updatePopup.style.display = 'none'
          })

          updatePopup.append(message)
          updatePopup.append(button)

          root.append(updatePopup)
        }
      }
    }, 0)
  }
}
