// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable class-methods-use-this */
import localForage from 'localforage'
import IsBrowser from '../../utils/is-browser'

type Language = string

interface CensoredWordsLogic {
  fetchWordsFromAPI: (language: Language) => Promise<string[]>
  fetchWordsFromIndexedDB: (language: Language) => Promise<string[]>
  storeWordsInIndexedDB: (language: Language, words: string[]) => Promise<string[]>
  backupWords: (language: Language) => Promise<void>
}

const CENSORED_ENDPOINT = process.env.GATSBY_CENSORED_URL

class CensoredWords implements CensoredWordsLogic {
  private store: LocalForage

  private WORD_STORAGE_KEY = 'censored_words_for'

  private constructor() {
    this.store = localForage
  }

  private getStorageKey(language: Language): string {
    return `${this.WORD_STORAGE_KEY}_${language}`
  }

  public async fetchWordsFromAPI(language: Language): Promise<string[]> {
    try {
      const response = await fetch(CENSORED_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify({
          language,
        }),
      })
      const json = await response.json()
      return json?.words || []
    } catch (e) {
        console.log(">>ERR", e)
        return []
    }
  }

  public async fetchWordsFromIndexedDB(language: Language): Promise<string[]> {
    const words: string[] = await this.store.getItem(this.getStorageKey(language))
    return words
  }

  public async storeWordsInIndexedDB(language: Language, words: string[]): Promise<string[]> {
    return this.store.setItem(this.getStorageKey(language), words)
  }

  public async backupWords(language: Language): Promise<void> {
    const storedWords = await this.fetchWordsFromIndexedDB(language)

    if (!storedWords || storedWords.length <= 0) {
      const apiWords = await this.fetchWordsFromAPI(language)
      await this.storeWordsInIndexedDB(language, apiWords)
    }
  }

  public static newInstance() {
    return new CensoredWords()
  }
}

if (IsBrowser()) {
  localForage.config({
    name: 'glenfiddich',
    driver: [localForage.INDEXEDDB, localForage.LOCALSTORAGE],
  })
}

const CensoredWordsInstance = CensoredWords.newInstance()
export default CensoredWordsInstance
export { CensoredWords }
