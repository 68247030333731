export const DEFAULT_IMAGE_CLASS = 'e__img'
export const DEFAULT_QUOTE_CLASS = 'e__blockquote'
export const DEFAULT_BUTTON_CLASS = 'e__button'
export const DEFAULT_INPUT_CLASS = 'e__input'
export const DEFAULT_LABEL_CLASS = 'e__label'
export const DEFAULT_SELECT_CLASS = 'e__select'
export const DEFAULT_TEXTAREA_CLASS = 'e__textarea'
export const DEFAULT_LIST_CLASS = 'e__list'
export const DEFAULT_SECTION_CLASS = 'u__section'
export const DEFAULT_INPUT_FIELD_CLASS = 'c__input-field'
export const DEFAULT_INPUT_FIELD_ERROR_CLASS = `${DEFAULT_INPUT_FIELD_CLASS}--error`
export const DEFAULT_INPUT_FIELD_MAX_LENGTH_CLASS = `${DEFAULT_INPUT_FIELD_CLASS}--with-max-length`
export const DEFAULT_INPUT_FIELD_TOOLTIP_CLASS = `${DEFAULT_INPUT_FIELD_CLASS}--with-tooltip`
