import React from 'react'
import { Elements } from '../../types/elements'
import { DEFAULT_LABEL_CLASS } from './_config'

export default function Label({ className = '', children, htmlFor, isRequired = false, ...rest }: Elements.Label) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <label className={`${DEFAULT_LABEL_CLASS} ${className}`} htmlFor={htmlFor} {...rest}>
      {children || 'NOT PROVIDED'} {isRequired && <span className="required-star">*</span>}
    </label>
  )
}
