// Enums don't work in .d.ts files

// eslint-disable-next-line import/prefer-default-export
export enum AppStateDispatchType {
  updateAppLanguage,
  updateChannel,
  updateRegion,
  updateStore,
  updateFTU,
  updateAppState,
  updateVideoDownloaded,
  updateCompletedTestPrint,
}

export enum BottleSelectDispatchType {
  enableStepTwo,
  backToStepOne,
}

export enum EditorDispatchType {
  updateField,
  nextStep,
  previousStep,
  updateFormFocus,
  changeStep,
}

export enum EditScreens {
  personalise,
  confirm,
  printing,
  videoTutorial,
  finish,
}

/**
 * Ensure that any updates here is reflected in gatsby-node.js
 * The variable should be an array of the enum keys
 * eg. ['en', 'af']
 *
 * @ref LANGUAGES
 */
export const Languages = {
  en: 'English',
  zh_Hans: 'Chinese Simplified',
  zh_Hant: 'Chinese Traditional',
} as const

export const GTRRegions = {
  indiaSubcontinentMiddleEast: 'India Subcontinent and Middle East',
  southEastAsia: 'South East Asia',
  northAsia: 'North Asia',
  australiaNewZealand: 'Australia New Zealand',
  uk: 'UK',
} as const

export const AustraliaDomesticRegions = {
  act: 'ACT',
  nsw: 'NSW',
  vic: 'VIC',
  sa: 'SA',
  qld: 'QLD',
  wa: 'WA',
  tas: 'TAS',
} as const

export const newZealandDomesticRegions = {
  newZealand: 'New Zealand',
} as const

// edited india
export const IndiaDomesticRegions = {
  ludhiana:'Ludhiana',
  delhi:'Delhi',
  gurgaon:'Gurgaon',
  jaipur:'Jaipur',
  chennai:'Chennai',
  mumbai:'Mumbai',
  pune:'Pune',
  goa:'Goa',
  bangalore:'Bangalore',
  hyderabad:'Hyderabad',
  kolkata:'Kolkata',
  chandigarh:'Chandigarh',
  indore:'Indore',
} as const


// edited india
export const Channels = {
  globalTravelRetail: 'Global Travel Retail',
  autraliaDomestic: 'Australia Domestic',
  newZealandDomestic: 'New Zealand Domestic',
  IndiaDomestic: 'India Domestic',
} as const

export const StoreNames = {
  // UK
  lhr_t2_destinationbar_slot1: 'LHR T2 Destination Bar Slot1',
  lhr_t5_whiskey_bar: 'LHR T5 Whiskey bar',
  // India Subcontinent and Middle East
  hyderabad_airport: 'Hyderabad Airport',
  flemingo_location: 'Flemingo Location',
  beirut: 'Beirut',
  bahrain: 'Bahrain',
  // South East Asia
  singapore_changi: 'Singapore Changi',
  // North Asia
  taipei: 'Taipei',
  hong_kong: 'Hong Kong',
  cdfg_haitang_sis: 'CDFG Haitang SIS',
  cdfg_haikuo_sis: 'CDFG Haikuo SIS',
  lagardere_hainan_sis: 'Lagardere Hainan SIS',
  shanghai_a1: 'Shanghai A1',
  hainan_htb_3f: 'Hainan HTB 3F',
  htdf_sis_b1: 'HTDF SIS B1',
  sanya_french_garden: 'Sanya French Garden',
  taipei_3028_sis: 'Taipei 3028 SIS',
  // Australia New Zealand
  sydney_airport_t1: 'Sydney Airport T1',
  auckland_lagardere: 'Auckland Lagardere',
  auckland_aer_rianta: 'Auckland AER RIANTA',
  melbourne_departure: 'Melbourne Departure',
  // ACT
  market_cellars_fyshwick: 'MARKET CELLARS - FYSHWICK',
  jim_murphy_cellars_canberra: 'JIM MURPHY CELLARS - CANBERRA',

  // VIC
  cambridge_cellars_caulfield: 'CAMBRIDGE CELLARS - CAULFIELD',
  lamanna_direct_essendon: 'LAMANNA DIRECT - ESSENDON',
  gladstone_park_hotel_tullamarine: 'GLADSTONE PARK HOTEL - TULLAMARINE',
  bottle_o_sanctuary_lakes: 'BOTTLE O - SANCTUARY LAKES',
  mccoppins_liquor_fitzroy: 'MCCOPPINS LIQUOR - FITZROY',
  ms_collins: 'MS COLLINS',
  mulgrave_cellars: 'MULGRAVE CELLARS',
  candela_nuovo: 'CANDELA NUOVO',
  iga_carrum_downs: 'IGA - CARRUM DOWNS',
  crown_cellar_co: 'CROWN CELLAR & CO.',
  // NSW
  billabong_cellars_griffith: 'BILLABONG CELLARS - GRIFFITH',
  east_toongabbie_liquor: 'EAST TOONGABBIE LIQUOR',
  charlies_liquor_barn_panania: 'CHARLIES LIQUOR BARN - PANANIA',
  quakers_hill_cellars: 'QUAKERS HILL CELLARS',
  lugarno_cellars: 'LUGARNO CELLARS',
  warners_at_the_bay_hotel: 'WARNERS AT THE BAY HOTEL',
  institchu: 'INSTITCHU',
  boozebud: 'BOOZEBUD',
  iga_ritchies: 'IGA - RITCHIES',
  bel_brio_nsw: 'BEL & BRIO - NSW',
  harrys_liquor_minchinbury: "HARRY'S LIQUOR MINCHINBURY",
  clemton_park_cellars: 'CLEMTON PARK CELLARS',
  head_office: 'HEAD OFFICE',
  shortys_liquor_pyrmont: "SHORTY'S LIQUOR - PYRMONT",
  // SA
  parafield_liquor_store_pals: 'PARAFIELD LIQUOR STORE (PALS)',
  parade_cellars_norwood: 'PARADE CELLARS - NORWOOD',
  pals_liquor_sa: 'PALS LIQUOR - SA',
  arkaba_hotel_fullarton: 'ARKABA HOTEL - FULLARTON',
  // WA
  cellarbrations_subiaco: 'CELLARBRATIONS - SUBIACO',
  copper_and_oak_liquor_tuart_hill: 'COPPER AND OAK LIQUOR - TUART HILL',
  crown_perth: 'CROWN PERTH',
  liquor_shed_wa: 'LIQUOR SHED - WA',
  liquor_shed_jandakot: 'LIQUOR SHED - JANDAKOT',
  // QLD
  ashmore_tavern: 'ASHMORE TAVERN',
  runcorn_tavern_sunnybank_hills: 'RUNCORN TAVERN - SUNNYBANK HILLS',
  bohle_barn_townsville: 'BOHLE BARN - TOWNSVILLE',
  // TAS
  brooker_inn_moonah: 'BROOKER INN - MOONAH',
  gas_works_hotel_hobart: 'GAS WORKS HOTEL - HOBART',
  bottleshop_gasworks: '911 BOTTLESHOP GASWORKS',
  bottleshop_st_ives: '911 BOTTLESHOP ST IVES',

  // New Zealand
  auckland: 'Auckland',

  //India
  na: 'N/A',

} as const
