// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable class-methods-use-this */

import { Keys } from '../../types/generic'
import IsBrowser from '../../utils/is-browser'

interface SendLabelParameter {
  mainField: string
  subField?: string
  batchField?: string
}

class GoogleAnalytics {
  private static instance: GoogleAnalytics

  private readonly MAIN_HEADING_DIMENSION = 'main_heading'

  private readonly SUB_HEADING_DIMENSION = 'sub_heading'

  private readonly BATCH_FIELD_DIMENSION = 'batch_field'

  private readonly STORE_NAME_DIMENSION = 'store_name'

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static getInstance(): GoogleAnalytics {
    if (!GoogleAnalytics.instance) {
      GoogleAnalytics.instance = new GoogleAnalytics()
    }

    return GoogleAnalytics.instance
  }

  private gtag(): UniversalAnalytics.ga {
    return (window as any).gtag
  }

  public configureDimensions() {
    if (IsBrowser() && this.gtagIsAvailable()) {
      this.gtag()('config', process.env.GATSBY_GA, {
        custom_map: {
          dimension10: this.STORE_NAME_DIMENSION,
          dimension11: this.MAIN_HEADING_DIMENSION,
          dimension12: this.SUB_HEADING_DIMENSION,
          dimension13: this.BATCH_FIELD_DIMENSION,
        },
      })
    }
  }

  private gtagIsAvailable(): boolean {
    return typeof this.gtag() !== 'undefined'
  }

  private sendLabelMessageEvent(category: string, label: string) {
    this.gtag()('event', 'label_creation_submission', {
      event_category: category,
      event_label: label,
      [category]: label,
    })
  }

  public sendLabelMessage({ mainField, subField, batchField }: SendLabelParameter) {
    if (IsBrowser() && this.gtagIsAvailable()) {
      this.sendLabelMessageEvent(this.MAIN_HEADING_DIMENSION, mainField)

      if (subField) {
        this.sendLabelMessageEvent(this.SUB_HEADING_DIMENSION, subField)
      }

      if (batchField) {
        this.sendLabelMessageEvent(this.BATCH_FIELD_DIMENSION, batchField)
      }
    }
  }

  public sendLabelCreationStart(store: Keys.StoreNames | '') {
    if (IsBrowser() && this.gtagIsAvailable()) {
      this.gtag()('event', 'label_creation_started', {
        event_category: store,
        [this.STORE_NAME_DIMENSION]: store,
      })
    }
  }

  public sendLabelCreationFinish(store: Keys.StoreNames | '') {
    if (IsBrowser() && this.gtagIsAvailable()) {
      this.gtag()('event', 'label_creation_finished', {
        event_category: store,
        [this.STORE_NAME_DIMENSION]: store,
      })
    }
  }
}

export default GoogleAnalytics.getInstance()
