import React from 'react'
import { Helmet } from 'react-helmet'
import { LoadingSVG, Section } from '../elements'

export default function Loading() {
  return (
    <Section>
      <Helmet>
        <html className="theme--light" lang="en" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="apple-mobile-web-app-title" content="Glenfiddich Label Designer" />
      </Helmet>
      <div className="flex direction-column align-center">
        <h2>Loading application</h2>
        <LoadingSVG />
      </div>
    </Section>
  )
}
