import React, { ReactElement } from 'react'
import { Elements } from '../../../types/elements'

const DARK_HEX = '#483D3C'
const LIGHT_HEX = '#FFFFFF'

type Icon = {
  hex: string
}

const HamburgerClosed = ({ hex }: Icon): ReactElement => (
  <svg width="31" height="22" viewBox="0 0 31 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.0917969 1C0.0917969 0.447715 0.539512 0 1.0918 0H29.0918C29.6441 0 30.0918 0.447715 30.0918 1C30.0918 1.55228 29.6441 2 29.0918 2H1.0918C0.539513 2 0.0917969 1.55228 0.0917969 1Z"
      fill="#000000"
    />
    <path
      d="M0.0917969 21C0.0917969 20.4477 0.539512 20 1.0918 20H29.0918C29.6441 20 30.0918 20.4477 30.0918 21C30.0918 21.5523 29.6441 22 29.0918 22H1.0918C0.539513 22 0.0917969 21.5523 0.0917969 21Z"
      fill="#000000"
    />
    <path
      d="M0.0917969 11C0.0917969 10.4477 0.539512 10 1.0918 10H29.0918C29.6441 10 30.0918 10.4477 30.0918 11C30.0918 11.5523 29.6441 12 29.0918 12H1.0918C0.539513 12 0.0917969 11.5523 0.0917969 11Z"
      fill="#000000"
    />
  </svg>
)

const HamburgerOpen = ({ hex }: Icon): ReactElement => (
  <svg width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.70696 0.707107C6.09748 0.316583 6.73065 0.316583 7.12117 0.707107L26.9202 20.5061C27.3107 20.8966 27.3107 21.5298 26.9202 21.9203C26.5296 22.3108 25.8965 22.3108 25.5059 21.9203L5.70696 2.12132C5.31643 1.7308 5.31643 1.09763 5.70696 0.707107Z"
      fill="#FFFFFF"
    />
    <path
      d="M5.70711 21.9205C5.31658 21.53 5.31658 20.8968 5.70711 20.5063L25.5061 0.707283C25.8966 0.316759 26.5298 0.316758 26.9203 0.707283C27.3108 1.09781 27.3108 1.73097 26.9203 2.1215L7.12132 21.9205C6.7308 22.311 6.09763 22.311 5.70711 21.9205Z"
      fill="#FFFFFF"
    />
  </svg>
)

export default function HamburgerIcon({ theme, isOpen }: Elements.HamburgerIcon): ReactElement {
  const hex = theme === 'dark' ? LIGHT_HEX : DARK_HEX

  return isOpen ? <HamburgerOpen hex={hex} /> : <HamburgerClosed hex={hex} />
}
