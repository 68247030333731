import React from 'react'

export default function TestPrintIcon() {
  return (
    <svg width="248" height="331" viewBox="0 0 248 331" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="247" height="330" rx="1.5" fill="white" />
      <rect x="0.5" y="0.5" width="247" height="330" rx="1.5" stroke="#000000" />
      <path
        d="M159.522 141.199C160.174 141.199 160.702 140.671 160.702 140.019V47.9793C160.702 47.3277 160.174 46.7993 159.522 46.7993H106.271C105.974 46.7993 105.689 46.9111 105.471 47.1122L87.9725 63.2531C87.9666 63.2584 87.9647 63.2665 87.959 63.2719C87.8727 63.3547 87.8102 63.4566 87.7522 63.5621C87.7344 63.5947 87.7051 63.6197 87.6904 63.6537C87.6285 63.797 87.5928 63.9541 87.5928 64.1202V140.019C87.5928 140.671 88.1211 141.199 88.7728 141.199H159.522ZM158.342 138.839H89.9528V65.3002H106.271C106.923 65.3002 107.451 64.7718 107.451 64.1202V49.1593H158.342V138.839ZM91.7923 62.9402L105.091 50.673V62.9402H91.7923Z"
        fill="#000000"
      />
      <g clipPath="url(#clip0)">
        <path
          d="M167.32 176.845V162.44V161H79.4834H78.2867V162.44V176.845H23V243.587H78.2867V282.24H167.32V243.347H224.761V176.845H167.32ZM80.6801 163.401H164.927V176.605H80.6801V163.401ZM164.927 279.599H80.6801V232.544H164.927V279.599ZM222.367 240.946H167.32V230.143H78.2867V240.946H25.3934V179.246H222.367V240.946Z"
          fill="#000000"
        />
        <path
          d="M25.3936 179.246V240.946H78.2869V230.143H167.32V240.946H222.368V179.246H25.3936ZM43.8225 190.77H32.813V188.369H43.8225V190.77ZM63.2087 190.77H52.1992V188.369H63.2087V190.77Z"
          fill="white"
        />
        <path d="M155.353 247.188H90.4932V249.589H155.353V247.188Z" fill="#000000" />
        <path d="M155.353 257.272H90.4932V259.673H155.353V257.272Z" fill="#000000" />
        <path d="M155.113 267.355H90.2529V269.756H155.113V267.355Z" fill="#000000" />
        <path d="M43.822 188.369H32.8125V190.77H43.822V188.369Z" fill="#000000" />
        <path d="M63.2087 188.369H52.1992V190.77H63.2087V188.369Z" fill="#000000" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="202" height="121" fill="white" transform="translate(23 161)" />
        </clipPath>
      </defs>
    </svg>
  )
}
