// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from 'react'
import { Link } from 'gatsby'
import { Elements } from '../../types/elements'
import { DEFAULT_BUTTON_CLASS } from './_config'

const getButtonClass = (
  classes: string,
  iconDirection: Elements.ButtonProperties['iconDirection'],
  variant: Elements.ButtonProperties['variant'] = 'default',
): string => {
  return `${DEFAULT_BUTTON_CLASS} ${classes} ${variant !== 'default' ? `${DEFAULT_BUTTON_CLASS}--${variant}` : ''} ${
    iconDirection ? `${DEFAULT_BUTTON_CLASS}--icon ${DEFAULT_BUTTON_CLASS}--icon--${iconDirection}` : ''
  }`
}

export function Button({
  variant = 'default',
  children,
  className = '',
  type = 'button',
  iconDirection,
  ...rest
}: Elements.ButtonButton): ReactElement {
  const classes = getButtonClass(className, iconDirection, variant)

  return (
    // eslint-disable-next-line react/button-has-type
    <button type={type} className={classes} {...rest}>
      {children}
    </button>
  )
}

export function AnchorButton({
  variant = 'default',
  children,
  className = '',
  activeClassName = '',
  iconDirection,
  href,
  ...rest
}: Elements.AnchorButton): ReactElement {
  const classes = getButtonClass(className, iconDirection, variant)

  return (
    <Link to={href} className={classes} activeClassName={activeClassName} {...rest}>
      {children}
    </Link>
  )
}
