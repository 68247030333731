import React from 'react'

export default function AccordionCollapsedIcon() {
  return (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15.5" cy="15.5" r="15" fill="white" stroke="#000000" />
      <path d="M15 8H16V23H15V8Z" fill="#000000" />
      <path d="M8 16L8 15L23 15V16L8 16Z" fill="#000000" />
    </svg>
  )
}
