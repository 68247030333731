// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from 'react'
import { Elements } from '../../types/elements'
import noop from '../../utils/noop'
import Label from './label'
import { DEFAULT_SELECT_CLASS } from './_config'

export default function Select({ label, select }: Elements.SelectElement): ReactElement {
  const {
    options,
    value,
    name = '',
    id = '',
    className = '',
    onChange = noop,
    required = false,
    ...selectRest
  } = select

  return (
    <>
      <Label
        {...label}
        // isRequired={required || label.isRequired}
        className={label.className || ''}
        htmlFor={label.htmlFor || name}
      />
      <select
        className={`${DEFAULT_SELECT_CLASS} ${value === '' ? `${DEFAULT_SELECT_CLASS}--empty` : ''} ${className}`}
        value={value}
        name={name}
        id={id}
        onChange={onChange}
        required={required}
        {...selectRest}
      >
        {options.map((option) => (
          <option key={`${id || name}--${option.value}`} value={option.value}>
            {option.content}
          </option>
        ))}
      </select>
    </>
  )
}
