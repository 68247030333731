import React from 'react'

// eslint-disable-next-line react/require-default-props
export default function StackedLogo({ className = '' }: { className?: string }) {
  return (
    <svg
      width="616"
      height="425"
      viewBox="0 0 616 324"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
	<path d="M340,79.6c-10.5,0.6-14.7,1.7-19,5.8c-5.8,5.3-3.9,16.7-3.8,17.1c0.4,3-0.4,5.1-2.2,5.9 c-0.7,0.2-1.4,0.3-2.3,0.3c-2.9,0-6.7-1.5-10.5-3c-3.7-1.4-7.4-2.8-11-3.3c-0.3-0.1-0.3-0.3-0.2-0.5c0.1-0.4,0.1-0.8,0.1-1.2 c-0.1-0.5-0.3-0.6-0.5-0.7c-1.5-0.3-2.8-0.5-4-0.5c-1.5,0-2.7,0.3-3.6,0.8c-0.5,0.3-0.5,0.5-0.4,0.9c0.5,1.5,0.7,4.2,3.3,3.9 c1.4-0.1,2.2-0.1,2.8-0.1c11.4,2,22.7,10,28,20c4.6,8.8,6.5,17.8,2.8,28.3c-0.1,0.2-0.3,0.2-0.3-0.1c-1.6-12.7-9.5-16.8-14.8-21.3 c-4.4-3.6-8.6-6.3-8.3-13.3c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.2-0.6-0.2-0.7,0.1c-5.4,7.4-5.5,13.1-2.2,17.6c0.1,0.1,0,0.2-0.1,0.2 c-1.8-0.8-2.7-1.7-3.5-2.4c-1.1-1-2-1.7-4.2-1.9c-0.3,0-0.7,0-1,0c-14.4,0-27,9.8-31.6,15.9c-0.2,0.3-0.2,0.5-0.2,0.6 c0.1,0.2,0.4,0.3,0.7,0.1c9.2-7.6,54.8-9.1,66.2,27c0.1,0.2,0.2,0.3,0.4,0.3c0.2,0,0.4-0.2,0.4-0.3c0.7-2.1,2.2-4.8,4.6-8.5 c2.8-4.4,21.2-25.8-1.7-69.7c-1.4-3.1-1.5-4.2-1.2-4.6c0.1-0.1,0.2-0.2,0.6-0.2c0.6,0,1.3,0.3,2.1,0.5c0.9,0.3,1.9,0.7,2.7,0.6 c9-0.5,13.8-11.8,13.9-12c0.4-0.9,0.4-1.6,0.2-1.9C340.9,79.8,340.6,79.5,340,79.6z"
		fill="#FFFFFF"/>
	<path d="M383.7,34.5c-0.3-0.1-0.7,0.3-1.3,1.2c-1.2,1.7-4.8,5.3-7.2,7.4c-0.1,0.1-0.2,0-0.2-0.2
		c0.9-2.8,0.9-5.9,0.9-8.4c0.1-1.6,0.4-2.7,0.6-3.3c0.1-0.2,0.2-0.6,0.4-1c1.6-3.9,6.4-15.6-3.5-29.2c-0.1-0.2-0.9-1.2-1.4-1
		c-0.5,0.3,0.1,1.5,0.2,1.7c4.5,9.6,3.4,17.4,2.2,20.2c-0.5,1.1-0.9,1.3-1,1.3c-0.1,0-0.1,0-0.1,0c-0.9,0-2.2-2.3-3.4-4.4
		c-0.7-1.2-1.5-2.5-2.2-3.5C363,9,358.5,5.7,351.8,3.8c-0.6-0.2-3.1-0.9-3.4-0.2c-0.3,0.7,2.1,1.6,2.8,2c7.4,3.8,9.5,7.8,10.8,10
		c0,0.1,0.2,0.4-0.2,0.4c-2-0.1-5.2-0.8-9.2-1.6c-2.7-0.6-5.6-1.3-8.8-1.9c-0.3-0.1-1.6-0.3-1.8,0.2s1.1,1.1,1.3,1.2
		c4.4,2.4,8.3,3.7,11.8,4.9c4.2,1.4,7.4,2.6,9.7,4.9c0.7,0.7,1.3,1.5,1.7,2.3c1.9,3.5,3.5,9.4,2.1,14.8c-1.1,3.9-3.7,7.1-7.7,9.4
		c-4.5,2.4-3.7-0.2-3.5-0.6c1.4-5.5,1.4-9.4,1.2-13.5c-0.1-1-0.3-4.5-0.9-4.5c-0.6,0-0.9,3.1-1.1,3.9c-0.6,3.4-1.6,8-3.6,12.3
		c-1.7,3.7-3.5,7.4-6.9,9.1c-0.3,0.2-7,3-10.1,3.8c-0.2,0.1-1.8,0.7-0.9-1.7c0.9-2.8,2.9-8.6,1.4-18.4c-0.1-0.2-0.4-3.2-1.2-3.1
		c-0.7,0.1-0.8,3-0.8,3.3c-0.3,3.5-0.8,6.2-1.3,8.5c-1.1,3.8-2.8,7.2-5.4,10.9c-0.9,1.2-5,7.2-8.8,7.2c-1.5,0-3.4-1.1-5-3
		c-2.1-2.4-5.5-8-4.9-18.9c0.1-0.6,0.3-1.7-0.3-1.9c-0.7-0.1-1.2,1.7-1.3,1.8c-4.1,12.2-0.2,20.4,1.3,23.6c0.2,0.4,0.3,0.8,0.4,1
		c0.4,0.9,0.3,1.9-0.1,2.8c-0.8,1.9-3,3.3-4.8,4c-0.9,0.3-1.7,0.6-2.3,0.6c-0.3,0.1-0.3-0.2-0.2-0.2c1.4-0.8,1.9-1.7,1.9-2.5
		c-0.1-1.3-1.9-2.1-3.9-3c-1.4-0.6-2.8-1.2-3.3-1.9c-1.2-1.7-2.4-3.1-3.5-4.5c-3.7-4.5-6.5-8-6.7-15.4c0-1,0.3-3.2-0.3-3.2
		c-0.8,0-1.5,2.5-1.6,2.7c-1.9,7.4,0.3,12.7,1.5,15.3c0.1,0.2,0.2,0.5,0.3,0.7c0.2,0.3,0.4,1-0.4,0.6c-4.3-1.7-7.2-4.8-8.7-9.4
		c-2-6.2-0.7-13-0.2-13.9c1.8-3,4.4-4.7,7.9-7c3-2,6.7-4.5,10.8-8.5c0.3-0.3,0.6-0.8,0.4-1c-0.7-0.8-3.8,1.3-5,1.9
		c-5.4,2.7-9.3,4.6-12,5.6c-0.5,0.3-0.4-0.2-0.4-0.3c3.6-8.9,8.4-14.9,15.1-18.9c0,0,1.6-0.9,1.3-1.5c-0.3-0.6-3.6,0.8-4.2,1
		c-11.8,5.3-16.2,14.2-18.5,20.3c0,0-1.1,3.5-1.8,2.7c-3.1-3.3-5-10.1-0.3-18.1c0.1-0.2,0.6-0.9,0.2-1.2c-0.3-0.2-0.7,0.1-1.3,0.7
		c-4.4,4.2-5.1,10.9-4.8,14.3c0.5,5.8,3.3,9.1,4.8,10.9c0.5,0.6,1,1,0.9,2.1c-0.3,3.9-0.3,5.3-0.3,7.3c0,0.8-0.6,0.8-0.8,0.7
		c-4.4-1.6-6.5-5.8-6.2-12.7c0-0.5,0.3-2.4-0.4-2.4c-0.7,0-1.1,2-1.2,2.3c-0.9,3.5-2,7.6,0.5,12.8c1.9,4,5.1,5.8,7.4,7
		c1.3,0.7,2.4,1.3,2.8,2c4.4,7.7,13.6,11.3,19.7,13.7c2.8,1.1,5,1.9,5.5,2.7c0.2,0.3,0.2,0.6,0.2,0.8c-0.2,0.6-1.5,1.1-2.6,1.5
		c-0.7,0.2-1.3,0.5-1.6,0.7c-0.7,0.6-0.6,1.6-0.6,2.4c0.1,0.3,0.1,0.8,0,0.9c-0.5,1-6.8,2.6-8.7,3c-2.2,0.5-2.4,3.3-1.7,5.1
		c-0.4,0.4-1.4,1.6-0.9,3.1c0.2,0.3,0.5,1,0.7,1.2c0.2,0.2,0.3,0.2,0.7,0.1c0.4-0.2,1.7-0.5,2.9-0.5c3.2,0,4.6,0.5,4.8,0.5
		c0.2,0,0.3-0.1,0.4-0.2c0.7-0.7,0.3-3.3,0.3-3.3c0-0.5-0.4-0.8-0.8-0.8c-0.3,0-0.8,0.2-1.2,0.5c-0.3,0.1-0.5-0.1-0.6-0.2
		c-0.4-0.5-0.7-1.3-0.6-2.1c0.1-1.3,0.6-1.9,1.6-2c0.3-0.1,0.7,0.1,0.9,0.4c0.1,0.1,0.3,0.3,0.7,0.1c1.4-0.6,9.4-2.8,11.1-3
		c0.8-0.1,1.2-0.8,1.8-1.7c0.9-1.4,2.2-3.3,5.1-3.8c0.3-0.1,0.7-0.1,1.1-0.1c2.2,0,3.5,1.3,4.5,2.3c0.7,0.6,1.2,1.1,1.8,1.1
		c0,0-0.8,0.1,0,0c0.8-0.1,3.9-1.4,3.3-3.3c0-0.1-0.9-2.3-0.1-4.2c0.8-1.7,1.3-2.3,3.3-3.9c2.4-1.7,7-3.1,12.9-4.9
		c15-4.6,37.8-11.6,47.2-31.4C383.7,36.4,384.2,34.6,383.7,34.5z" fill="#FFFFFF"/>
	<path d="M314.6,87.3c-0.2-0.8-1.9-3.4-4.4-3.4c-1.7,0-2.7,0.9-3.3,1.7c-0.3,0.3-0.6,0.8-1.2,1c-0.7,0.3-1.3,0.7-1.4,1.1
		c0,0.1,0,0.2,0.1,0.3c0.1,0.2,0.3,0.2,0.7,0.2c0,0,0.3,0,0.6-0.1c0.3-0.1,0.8-0.1,1.4-0.2c0.9,0,1.6,0.3,1.6,0.3
		c0.1,0.1,0.4,0.2,0.7,0.2c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.6,0.3-1.1c0-0.3,0-0.7,0.2-0.9c0.1-0.1,0.3-0.2,0.5-0.2
		c0.4,0,1.2,0.2,1.5,1.6c0.1,0.3,0.4,0.6,0.7,0.6c0.5,0,0.9-0.2,1.1-0.5C314.6,87.7,314.6,87.6,314.6,87.3z" fill="#FFFFFF"/>
	<path d="M201.2,238.5h-3.7l0,12.6c-2.4,4.6-6.4,12.3-14.1,12.3c-4.2,0-7.7-2.3-11-6.9c-4-5.8-6.4-15.6-6.4-26.3
		c0-19.1,8.2-34,18.8-34c4.3,0,6.2,2.4,8.1,4.6l1.8-3.7h2.2v0.2l0.2,19.4h-2.4l0-0.4c-0.3-2.9-1.1-8.9-3.6-13.3
		c-2-3.5-4.4-4.3-6.1-4.3c-1.5,0-3.1,0.7-4.4,2c-2.5,2.4-3.4,6.1-3.8,8.6c-0.8,4.7-1,15.3-1,23.2c0,6.3,0.3,11.6,0.8,16.9
		c0.6,7.4,2.9,11.1,6.8,11.1c1.2,0,3-0.4,4.2-2.1c1.6-2.3,1.6-6.3,1.6-9.3v-10.8H185v-2.5h16.3L201.2,238.5L201.2,238.5z
		M212.8,197.1H201v2.4h4.1v60.7H201v2.4h15.9v-2.4h-4.1L212.8,197.1L212.8,197.1z M239,222.9c4.6,4.8,4.4,12.3,4.4,16.8v0.2h-17.1
		v0.3c-0.1,8.3-0.1,13.8,1.2,16.8c0.9,2.1,2.6,3.4,4.5,3.4c5.9,0,7.7-9.3,8.4-12.8l0-0.2l2.5,0.5l-0.1,0.4
		c-1.2,5.5-3.3,14.8-11.4,14.8c-7.9,0-13.5-8.6-13.5-20.9c0-12.9,6-22.2,14.2-22.2C234.8,219.9,237.2,221,239,222.9z M228.3,224.3
		c-1.7,2.3-1.7,5.5-1.9,13.1h9.6c0-0.7,0-1.4,0-2.1c0-2.3,0-4.7-0.1-7.2v0c-0.1-1.7-0.3-5.8-3.9-5.8
		C230.5,222.2,229.2,222.9,228.3,224.3z M274.3,230.1c0-3,0-7.5-4.1-9.5c-1.1-0.5-2.3-0.8-3.6-0.8c-5,0-7.5,3.5-8.9,5.9v-5.2h-11.9
		v2.4h4.2v37.3h-4.2v2.4h15.6v-2.4h-3.8v-22.8v-0.1c0.1-3.6,0.2-8,2.7-11.4c1.4-2.1,2.9-2.4,3.6-2.4c2.5,0,2.7,2.8,2.7,6.3v30.5H263
		v2.4h15.5v-2.4h-4.1V230.1z M310.9,220.5h-18.2v-8.1c0-5.8,0.2-8.6,1.9-10.9c1.4-1.8,3.5-2.8,6.1-2.8c4,0,5.4,2,5.4,2.4
		c0,0.3-0.2,0.4-0.7,0.8c-0.8,0.5-2.2,1.7-2.2,3.7s1.4,4.1,3.9,4.1c2.6,0,4.5-2.2,4.5-5.2c0-4.6-4.7-8-10.9-8
		c-5.3,0-9.9,2.3-12.6,6.3c-3,4.1-3,8.4-3.1,13.4v4.5H281v2.4h4.1v37.3H281v2.4h16.5v-2.4h-4.7v-37.3h10.6v37.3h-4.1v2.4h15.9v-2.4
		H311v-39.8H310.9z M342.8,260.2h4.1v2.4h-11.7V257c-1.1,3-2.8,6.3-6.8,6.3c-6.6,0-11-9-11-22.3c0-14.7,6.8-21.4,11.7-21.4
		c3.8,0,5.3,2.9,6.2,5.3v-25.4h-4.2v-2.4h11.9V260.2z M335.1,241.2c0-7.1-0.7-12.3-2.2-15.5c-0.4-1-1.6-3.2-3.4-3.2
		c-0.8,0-2,0.7-2.8,2.7c-1.1,3.1-1.1,7.6-1.1,17.4c0,7.8,0.2,10.8,0.7,13.8c0.3,1.5,1.1,4,3.1,4C332.3,260.4,335.1,253.8,335.1,241.2
		z M374,260.2h4.1v2.4h-11.7V257c-1.1,3-2.8,6.3-6.8,6.3c-6.6,0-11-9-11-22.3c0-14.7,6.8-21.4,11.7-21.4c3.8,0,5.3,2.9,6.2,5.3v-25.5
		h-4.2v-2.4H374V260.2z M366.3,241.2c0-7.1-0.7-12.3-2.2-15.5c-0.4-1-1.6-3.2-3.4-3.2c-0.8,0-2,0.7-2.8,2.7
		c-1.1,3.1-1.1,7.6-1.1,17.4c0,7.6,0.2,10.8,0.7,13.8c0.3,1.5,1.1,4,3.1,4C363.5,260.4,366.3,253.8,366.3,241.2z M392.3,220.5h-11.7
		v2.4h4.1v37.4h-4.1v2.4h15.9v-2.4h-4.1L392.3,220.5L392.3,220.5z M388.3,209.5c2.5,0,4.6-2.2,4.6-4.8c0-2.6-2.1-4.8-4.6-4.8
		c-2.5,0-4.6,2.2-4.6,4.8C383.8,207.3,385.9,209.5,388.3,209.5z M423,247.4l-2.3-0.4h-0.2l0,0.2c-0.7,3.6-1.5,7.8-4,10.7
		c-0.7,0.8-2.1,2.2-4.2,2.2c-2.5,0-3.4-1.9-3.9-2.6c-1.6-3.1-1.7-10.2-1.7-15.3c0-4,0-13.3,1.2-16.5c0.8-2,2-3.1,3.9-3.5
		c0.4-0.1,0.8-0.1,1.2-0.1c1.6,0,3.2,0.7,3.2,1.9c0,0.5,0,0.6-0.9,1.6l0,0c-0.9,1.1-1.3,2.2-1.3,3.5c0,2.4,1.7,4.2,4,4.2
		c2.1,0,4.3-1.6,4.3-5.1c0-4.1-3.3-8.4-9.5-8.4c-8.8,0-14.7,9-14.7,22.3c0,13.7,6.8,20.8,13.5,20.8c2.5,0,5-1.2,6.7-3.2
		c2.9-3.3,3.9-8.1,4.6-12L423,247.4z M451,229.8c0-2.8,0-6.4-3.3-8.7c-1.2-0.8-2.8-1.3-4.3-1.3c-2.5,0-5.1,1.2-7.1,3.4
		c-0.8,0.8-1.3,1.6-1.8,2.6v-28.7h-11.9v2.4h4.2v60.7h-4.2v2.4h15.6v-2.4h-3.8v-23.6c0.1-3.4,0.2-7.3,2.6-10.8
		c0.5-0.7,1.7-2.3,3.6-2.3c0.7,0,1.2,0.2,1.7,0.7c1.1,1.1,1.1,3.4,1.1,4.8v31.2h-3.6v2.4h15.4v-2.4H451L451,229.8L451,229.8z
		M463.3,257.8l1.4,2.4h-1l0,0l-1.4-2.2h-0.7v2.3h-0.9v-5.4h2c1.2,0,1.8,0.5,1.8,1.6C464.5,257.1,464.1,257.6,463.3,257.8z
		M461.6,257h0.7c0.7,0,1.2,0,1.2-0.8c0-0.5-0.3-0.7-1-0.7h-0.9V257z M467.2,257.4c0,2.8-2.1,5.1-4.9,5.1c-2.8,0-4.9-2.3-4.9-5.1
		c0-2.8,2.2-5.1,4.9-5.1C465,252.3,467.2,254.6,467.2,257.4z M466.2,257.4c0-2.3-1.7-4.1-3.8-4.1c-2.2,0-3.9,1.8-3.9,4.1
		c0,2.4,1.7,4.2,3.9,4.2C464.6,261.5,466.2,259.8,466.2,257.4z" fill="#FFFFFF"/>

	<path d="M0,287.6h14.7c2.5,0,4.8,0.5,6.8,1.4c2,0.9,3.5,2.2,4.6,3.9c1.1,1.6,1.7,3.5,1.7,5.6c0,2.1-0.6,4-1.7,5.6
		c-1.1,1.6-2.6,2.9-4.6,3.8c-2,0.9-4.3,1.4-6.8,1.4H4.1v14.2H0V287.6z M14.7,305.5c1.7,0,3.2-0.3,4.5-0.9c1.4-0.6,2.4-1.4,3.2-2.5
		c0.8-1.1,1.1-2.3,1.1-3.6c0-2.1-0.8-3.8-2.5-5.1c-1.7-1.3-3.8-2-6.4-2H4.1v14.2H14.7z" fill="#FFFFFF"/>
	<path d="M34.9,287.6h24.5v3.8H39v11.8h18.3v3.7H39v12.9h20.9v3.8h-25V287.6z" fill="#FFFFFF"/>
	<path d="M90.8,324c-0.7-0.4-1.2-0.8-1.7-1.5c-0.5-0.6-0.8-1.3-1-2.1l-2.2-7.6c-0.4-1.3-1.1-2.4-2-3.2
		c-1-0.8-2-1.2-3.3-1.2h-9v15.2h-4.3v-35.9h16.1c2.4,0,4.6,0.4,6.6,1.3c1.9,0.9,3.5,2.1,4.6,3.6c1.1,1.6,1.7,3.3,1.7,5.4
		c0,1.7-0.4,3.2-1.2,4.5c-0.8,1.3-1.9,2.5-3.4,3.4c-1.5,0.9-3.2,1.5-5.2,1.8v0.1c1,0.5,1.8,1.1,2.4,1.9c0.6,0.8,1.1,1.8,1.4,2.9
		l1.8,6.5c0.2,0.7,0.4,1.2,0.8,1.6c0.4,0.4,0.8,0.5,1.4,0.5c0.5,0,1.1-0.2,1.7-0.6c0.6-0.4,1.1-1,1.4-1.7l2.1,2.5
		c-0.7,1.1-1.6,1.9-2.7,2.5c-1.1,0.6-2.2,0.9-3.4,0.9C92.3,324.5,91.5,324.3,90.8,324z M83.4,304.5c1.7,0,3.1-0.3,4.5-0.8
		c1.3-0.6,2.3-1.3,3-2.3s1.1-2.1,1.1-3.4c0-1.3-0.4-2.4-1.1-3.4c-0.7-1-1.7-1.8-3-2.4c-1.3-0.6-2.8-0.8-4.5-0.8H71.5v13.2H83.4z" fill="#FFFFFF"/>
	<path d="M111.5,323.1c-2.3-1.1-4.1-2.6-5.5-4.5c-1.4-1.9-2.3-4.1-2.5-6.5l4.2-0.7c0.1,1.8,0.7,3.4,1.7,4.9
		c1,1.5,2.4,2.6,4.1,3.4c1.7,0.8,3.6,1.2,5.6,1.2c1.8,0,3.4-0.3,4.8-0.9c1.4-0.6,2.4-1.4,3.2-2.5c0.7-1,1.1-2.2,1.1-3.5
		c0-1.6-0.6-2.9-1.8-3.9c-1.2-1-3.1-1.8-5.7-2.4l-5.8-1.3c-3.2-0.7-5.6-1.9-7.1-3.3c-1.5-1.5-2.3-3.4-2.3-5.8c0-2.1,0.5-4,1.5-5.7
		c1-1.7,2.4-3,4.3-3.9c1.9-0.9,4-1.4,6.4-1.4c2.2,0,4.2,0.4,5.9,1.3c1.8,0.9,3.2,2.1,4.4,3.6c1.1,1.5,1.9,3.3,2.2,5.3l-3.9,1.1
		c-0.2-1.5-0.7-2.7-1.5-3.8c-0.8-1.1-1.8-2-3-2.6c-1.2-0.6-2.6-1-4.2-1c-1.6,0-3,0.3-4.2,0.8c-1.2,0.6-2.2,1.3-2.8,2.3
		c-0.6,1-1,2.1-1,3.3c0,1.5,0.5,2.8,1.5,3.7s2.7,1.6,4.9,2.1l5.8,1.4c3.4,0.7,6,1.9,7.8,3.5c1.7,1.6,2.6,3.6,2.6,6.1
		c0,2.2-0.6,4.1-1.7,5.9c-1.1,1.7-2.7,3.1-4.7,4.1c-2,1-4.4,1.5-7,1.5C116.3,324.7,113.8,324.2,111.5,323.1z" fill="#FFFFFF"/>
	<path d="M147.4,322.4c-2.9-1.6-5.2-3.8-6.8-6.8c-1.6-2.9-2.4-6.3-2.4-10.1c0-3.8,0.8-7.2,2.4-10.1
		c1.6-2.9,3.9-5.2,6.8-6.8c2.9-1.6,6.3-2.4,10.1-2.4c3.8,0,7.2,0.8,10.1,2.4c2.9,1.6,5.2,3.8,6.8,6.8c1.6,2.9,2.4,6.3,2.4,10.1
		c0,3.8-0.8,7.2-2.4,10.1c-1.6,2.9-3.9,5.2-6.8,6.8c-2.9,1.6-6.3,2.4-10.1,2.4C153.7,324.7,150.4,324,147.4,322.4z M165.5,319.1
		c2.3-1.3,4-3.1,5.3-5.4c1.2-2.3,1.9-5,1.9-8.1c0-3.1-0.6-5.8-1.9-8.2c-1.2-2.3-3-4.2-5.3-5.4c-2.3-1.3-4.9-1.9-7.9-1.9
		c-3,0-5.7,0.6-7.9,1.9c-2.3,1.3-4,3.1-5.3,5.4c-1.3,2.3-1.9,5.1-1.9,8.2c0,3.1,0.6,5.8,1.9,8.1c1.3,2.3,3,4.2,5.3,5.4
		c2.3,1.3,4.9,1.9,7.9,1.9C160.6,321,163.2,320.3,165.5,319.1z" fill="#FFFFFF"/>
	<path d="M184.8,287.6h5.7l18.4,30.5h0.1v-30.5h4.3v35.9h-5.7L189.1,293H189v30.5h-4.2V287.6z" fill="#FFFFFF"/>
	<path d="M233.8,287.6h5.1l13.9,35.9h-4.5l-3.3-8.6h-17.3l-3.3,8.6h-4.5L233.8,287.6z M243.8,311.1l-7.3-19.4h-0.1
		l-7.4,19.4H243.8z" fill="#FFFFFF"/>
	<path d="M259.4,287.6h4.2v32.2H282v3.8h-22.6V287.6z" fill="#FFFFFF"/>
	<path d="M288.6,287.6h4.2v35.9h-4.2V287.6z" fill="#FFFFFF"/>
	<path d="M308,323.1c-2.3-1.1-4.1-2.6-5.5-4.5c-1.4-1.9-2.3-4.1-2.5-6.5l4.2-0.7c0.1,1.8,0.7,3.4,1.7,4.9
		c1,1.5,2.4,2.6,4.1,3.4c1.7,0.8,3.6,1.2,5.6,1.2c1.8,0,3.4-0.3,4.8-0.9c1.4-0.6,2.4-1.4,3.2-2.5s1.1-2.2,1.1-3.5
		c0-1.6-0.6-2.9-1.8-3.9c-1.2-1-3.1-1.8-5.7-2.4l-5.8-1.3c-3.2-0.7-5.6-1.9-7.1-3.3c-1.5-1.5-2.3-3.4-2.3-5.8c0-2.1,0.5-4,1.5-5.7
		c1-1.7,2.4-3,4.3-3.9c1.9-0.9,4-1.4,6.4-1.4c2.2,0,4.2,0.4,5.9,1.3s3.2,2.1,4.4,3.6c1.1,1.5,1.9,3.3,2.2,5.3l-3.9,1.1
		c-0.2-1.5-0.7-2.7-1.5-3.8c-0.8-1.1-1.8-2-3-2.6c-1.2-0.6-2.6-1-4.2-1c-1.6,0-3,0.3-4.2,0.8c-1.2,0.6-2.2,1.3-2.8,2.3
		c-0.6,1-1,2.1-1,3.3c0,1.5,0.5,2.8,1.5,3.7s2.7,1.6,4.9,2.1l5.8,1.4c3.4,0.7,6,1.9,7.8,3.5c1.7,1.6,2.6,3.6,2.6,6.1
		c0,2.2-0.6,4.1-1.7,5.9s-2.7,3.1-4.7,4.1c-2,1-4.4,1.5-7,1.5C312.8,324.7,310.3,324.2,308,323.1z" fill="#FFFFFF"/>
	<path d="M336,287.6h24.5v3.8h-20.4v11.8h18.3v3.7h-18.3v12.9H361v3.8h-25V287.6z" fill="#FFFFFF"/>
	<path d="M368.4,287.6h13c6.1,0,10.7,1.6,14,4.7c3.2,3.1,4.9,7.5,4.9,13.3c0,3.8-0.7,7.1-2.2,9.8
		c-1.4,2.7-3.6,4.7-6.4,6.1c-2.8,1.4-6.3,2.1-10.3,2.1h-13V287.6z M381.4,319.7c3.1,0,5.8-0.6,8-1.7c2.2-1.1,3.9-2.7,5-4.8
		s1.7-4.7,1.7-7.7c0-4.6-1.3-8.1-3.8-10.5c-2.5-2.5-6.2-3.7-10.9-3.7h-8.8v28.4H381.4z" fill="#FFFFFF"/>
	<path d="M425.1,287.6h4.2v32.2h18.4v3.8h-22.6V287.6z" fill="#FFFFFF"/>
	<path d="M465.8,287.6h5.1l13.9,35.9h-4.5l-3.3-8.6h-17.3l-3.3,8.6h-4.5L465.8,287.6z M475.7,311.1l-7.3-19.4h-0.1
		l-7.4,19.4H475.7z" fill="#FFFFFF"/>
	<path d="M491.3,287.6h16.5c2.3,0,4.4,0.4,6,1.1c1.7,0.7,3,1.9,3.8,3.3c0.9,1.5,1.3,3.2,1.3,5.2c0,1.3-0.2,2.5-0.7,3.5
		c-0.5,1-1.1,1.9-1.9,2.6c-0.8,0.7-1.8,1.2-3,1.6v0.1c2.1,0.4,3.8,1.4,5,2.9c1.2,1.5,1.8,3.3,1.8,5.6c0,2.2-0.5,4-1.4,5.5
		c-1,1.5-2.4,2.6-4.2,3.4c-1.9,0.8-4.1,1.2-6.6,1.2h-16.5V287.6z M507.3,303.1c1.6,0,3-0.2,4.1-0.7c1.1-0.4,2-1.1,2.5-2
		c0.6-0.9,0.9-2,0.9-3.2c0-1.9-0.7-3.3-2-4.4s-3.1-1.6-5.5-1.6h-11.8v11.8H507.3z M507.3,319.7c1.8,0,3.4-0.3,4.7-0.8
		c1.3-0.5,2.3-1.2,3-2.2c0.7-1,1-2.1,1-3.5c0-2-0.8-3.6-2.3-4.7c-1.5-1.1-3.6-1.7-6.3-1.7h-11.7v12.8H507.3z" fill="#FFFFFF"/>
	<path d="M527.8,287.6h24.5v3.8h-20.4v11.8h18.3v3.7h-18.3v12.9h20.9v3.8h-25V287.6z" fill="#FFFFFF"/>
	<path d="M560.2,287.6h4.2v32.2h18.4v3.8h-22.6V287.6z" fill="#FFFFFF"/>
	<path d="M594.3,323.1c-2.3-1.1-4.1-2.6-5.5-4.5c-1.4-1.9-2.2-4.1-2.5-6.5l4.2-0.7c0.1,1.8,0.7,3.4,1.7,4.9
		c1,1.5,2.4,2.6,4.1,3.4c1.7,0.8,3.6,1.2,5.6,1.2c1.8,0,3.4-0.3,4.8-0.9c1.4-0.6,2.4-1.4,3.2-2.5s1.1-2.2,1.1-3.5
		c0-1.6-0.6-2.9-1.8-3.9c-1.2-1-3.1-1.8-5.7-2.4l-5.8-1.3c-3.2-0.7-5.6-1.9-7.1-3.3c-1.5-1.5-2.3-3.4-2.3-5.8c0-2.1,0.5-4,1.5-5.7
		c1-1.7,2.4-3,4.3-3.9s4-1.4,6.4-1.4c2.2,0,4.2,0.4,5.9,1.3s3.2,2.1,4.4,3.6c1.1,1.5,1.9,3.3,2.2,5.3l-3.9,1.1
		c-0.2-1.5-0.7-2.7-1.5-3.8c-0.8-1.1-1.8-2-3-2.6c-1.2-0.6-2.6-1-4.2-1c-1.6,0-3,0.3-4.2,0.8s-2.2,1.3-2.8,2.3s-1,2.1-1,3.3
		c0,1.5,0.5,2.8,1.5,3.7c1,0.9,2.7,1.6,4.9,2.1l5.8,1.4c3.4,0.7,6,1.9,7.8,3.5c1.7,1.6,2.6,3.6,2.6,6.1c0,2.2-0.6,4.1-1.7,5.9
		s-2.7,3.1-4.7,4.1c-2,1-4.4,1.5-7,1.5C599,324.7,596.5,324.2,594.3,323.1z" fill="#FFFFFF"/>
	</svg>
  )
}
