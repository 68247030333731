module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-RJLYFKKFRX"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Glenfiddich Label Designer","short_name":"Glenfiddich","start_url":"/","background_color":"#f7f0eb","theme_color":"#f7f0eb","display":"standalone","icon":"src/images/icon.png","cache_busting_mode":"none","orientation":"any","categories":["social","lifestyle","personalization"],"description":"Glenfiddich labels allows customers to input a custom message online and have the label shipped to them or enter a participating store and create the label with a staff member on the spot.","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"appendScript":"/opt/build/repo/src/sw/sw-custom-append.js","precachePages":["/","/en/*","/images/*","/fonts/*","/icons/*"],"workboxConfig":{"globPatterns":["**/*.{js,jpg,png,html,css,svg,otf,ttf}"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
