// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, RefObject } from 'react'
import { Elements } from '../../types/elements'
import noop from '../../utils/noop'
import Label from './label'
import { DEFAULT_INPUT_CLASS } from './_config'

const Control = forwardRef(({ label, input }: Elements.InputElement, reference: RefObject<HTMLInputElement>) => {
  const { className: inputClass = '', type = 'text', name, id, onChange = noop, required = false, ...inputRest } = input
  const { className: labelClass = '', htmlFor = name, children: labelChildren, ...labelRest } = label

  const LabelElement = () => (
    <Label isRequired={required} className={labelClass} htmlFor={htmlFor} {...labelRest}>
      {labelChildren}
    </Label>
  )

  return (
    <>
      {type !== 'radio' && type !== 'checkbox' ? <LabelElement /> : false}
      <input
        ref={reference}
        className={`${DEFAULT_INPUT_CLASS} ${inputClass} ${DEFAULT_INPUT_CLASS}__${type}`}
        type={type}
        name={name}
        id={id}
        onChange={onChange}
        required={required}
        {...inputRest}
      />
      {type === 'radio' || type === 'checkbox' ? <LabelElement /> : false}
    </>
  )
})

// export default function Control({ label, input }: Elements.InputElement): ReactElement {
//   const { className: inputClass = '', type = 'text', name, id, onChange = noop, required = false, ...inputRest } = input
//   const { className: labelClass = '', htmlFor = name, children: labelChildren, ...labelRest } = label

//   const LabelElement = () => (
//     <Label isRequired={required} className={labelClass} htmlFor={htmlFor} {...labelRest}>
//       {labelChildren}
//     </Label>
//   )

//   return (
//     <>
//       {type !== 'radio' && type !== 'checkbox' ? <LabelElement /> : false}
//       <input
//         className={`${DEFAULT_INPUT_CLASS} ${inputClass} ${DEFAULT_INPUT_CLASS}__${type}`}
//         type={type}
//         name={name}
//         id={id}
//         onChange={onChange}
//         required={required}
//         {...inputRest}
//       />
//       {type === 'radio' || type === 'checkbox' ? <LabelElement /> : false}
//     </>
//   )
// }

export default Control
