import React, { createContext, useContext, useEffect, useReducer, useState } from 'react'
import Loading from '../components/screens/loading'
import AppStateBackup from '../lib/app-state-backup'
import { AppStateDispatchType } from '../types/enums'
import { AppState } from '../types/state'
import IsBrowser from '../utils/is-browser'

const defaultState: AppState.State = {
  appLanguage: 'en',
  channel: '',
  region: '',
  store: undefined,
  firstTimeUser: true,
  videoDownloaded: false,
  hasCompletedTestPrint: false,
}

const AppStateContext = createContext<AppState.State>(defaultState)
// eslint-disable-next-line unicorn/no-useless-undefined
const AppDispatchContext = createContext<AppState.Dispatch | undefined>(undefined)

function appReducer(state: AppState.State, action: AppState.Action) {
  switch (action.type) {
    case AppStateDispatchType.updateAppLanguage:
      return { ...state, appLanguage: action.payload }
    case AppStateDispatchType.updateChannel:
      return { ...state, channel: action.payload }
    case AppStateDispatchType.updateRegion:
      return { ...state, region: action.payload }
    case AppStateDispatchType.updateStore:
      return { ...state, store: action.payload }
    case AppStateDispatchType.updateFTU:
      return { ...state, firstTimeUser: action.payload }
    case AppStateDispatchType.updateAppState:
      return action.payload
    case AppStateDispatchType.updateVideoDownloaded:
      return { ...state, videoDownloaded: action.payload }
    case AppStateDispatchType.updateCompletedTestPrint:
      return { ...state, hasCompletedTestPrint: action.payload }
    default:
      throw new Error(`Unhandled action type`)
  }
}

function AppProvider({ children }: AppState.AppProviderProperties) {
  const [loading, setLoading] = useState(true)
  const [state, dispatch] = useReducer(appReducer, defaultState)

  useEffect(() => {
    const fetchBackup = async () => {
      const backupState = await AppStateBackup.getInstance().getBackup()
      if (backupState) {
        dispatch({
          type: AppStateDispatchType.updateAppState,
          payload: { ...defaultState, ...backupState },
        })
      }

      setLoading(false)
    }

    if (IsBrowser()) {
      fetchBackup()
    }
  }, [dispatch])

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>{loading ? <Loading /> : children}</AppDispatchContext.Provider>
    </AppStateContext.Provider>
  )
}

function useAppState(): AppState.State {
  const context = useContext(AppStateContext)

  if (context === undefined) {
    throw new Error('useAppState must be used within a AppProvider')
  }

  return context
}

function useAppDispatch(): AppState.Dispatch {
  const context = useContext(AppDispatchContext)

  if (context === undefined) {
    throw new Error('useAppDispatch must be used within a AppProvider')
  }

  return context
}

export { AppProvider, useAppDispatch, useAppState }
