import React from 'react'

export default function AccordionExpandedIcon() {
  return (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15.5" cy="15.5" r="15" fill="#000000" stroke="#000000" />
      <rect x="8" y="16" width="0.999999" height="15" transform="rotate(-90 8 16)" fill="white" />
    </svg>
  )
}
